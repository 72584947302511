// TODO: unused, remove?
// eslint-disable-next-line no-redeclare,no-unused-vars
function processScan(object, objectSub, param) {
  buildScan(function (code) {
    var pm = {object: object, objectSub: objectSub, post: true, send: {}};
    pm.send[param.key] = code;
    pm.callback = function (xhr) {
      detail({object: object, objectSub: objectSub, id: xhr.id});
    };
    ajax(pm);
  });
}
